<template>
	<section class="eval-result">
		<div class="eval-result__head">
			<h1>{{ get(riskResult, "title") }}</h1>
			<span>{{ get(riskResult, "label") }}</span>
		</div>
		<div class="eval-result__body">
			<div class="form-group">
				<label for="question" class="form-group__label">{{
					$t("AssessmentResult.Question")
				}}</label>
				<div class="form-group__input grid">
					<div v-for="answer in answers" :key="answer.id" class="checkbox-item">
						<input
							:id="answer.id"
							v-model="dataForm.channel"
							class="form-check-input"
							type="checkbox"
							:value="answer.value"
						/>

						<label
							v-if="!isOtherCheck(dataForm.channel) || !isOtherCheck(answer.value)"
							class="form-check-label"
							:for="answer.id"
							>{{ answer.label }}</label
						>
						<label v-else class="w-100" :for="answer.id">
							<input
								:id="answer.id + 'text'"
								v-model="dataForm.channelText"
								type="text"
								class="form-control other"
								autocomplete="channelText"
							/>
						</label>
					</div>
				</div>
			</div>

			<hr class="h-divider" />

			<div class="text-right">
				<CButton color="primary" class="btn-finish" @click="finishAssessment">{{
					$t("RiskAssessment.Finish")
				}}</CButton>
			</div>
		</div>
	</section>
</template>

<script>
import { COMMON_CHECK } from "@/shared/plugins/constants"
import { get, isArray } from "lodash-es"
import { FINISH_ASSESSMENT, FINISH_ASSESSMENT_MOBILE } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("riskAssessment")

export default {
	name: "EvaluationResult",

	props: {
		isMobile: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			dataForm: {
				channel: [],
				channelText: null,
			},

			riskResult: null,

			answers: [
				{
					id: "check-1",
					label: this.$t("AssessmentResult.Answer1"),
					value: this.$t("AssessmentResult.Answer1"),
				},
				{
					id: "check-2",
					label: this.$t("AssessmentResult.Answer2"),
					value: this.$t("AssessmentResult.Answer2"),
				},
				{
					id: "check-3",
					label: this.$t("AssessmentResult.Answer3"),
					value: this.$t("AssessmentResult.Answer3"),
				},
				{
					id: "check-4",
					label: this.$t("AssessmentResult.Answer4"),
					value: this.$t("AssessmentResult.Answer4"),
				},
				{
					id: "check-5",
					label: this.$t("AssessmentResult.Answer5"),
					value: this.$t("AssessmentResult.Answer5"),
				},
				{
					id: "check-6",
					label: this.$t("AssessmentResult.Answer6"),
					value: this.$t("AssessmentResult.Answer6"),
				},
				{
					id: "check-7",
					label: this.$t("AssessmentResult.Answer7"),
					value: COMMON_CHECK.OTHER,
				},
			],
		}
	},

	computed: {},

	watch: {},

	async created() {
		this.riskResult = JSON.parse(localStorage.getItem("riskResult"))

		if (!this.riskResult) {
			const name = this.isMobile ? "AssessmentFormMobile" : "AssessmentForm"
			this.$router.push({ name: name })
		}

		await window.parent.postMessage("get-eval-result", "*")
	},

	methods: {
		get,

		...mapActions({ FINISH_ASSESSMENT, FINISH_ASSESSMENT_MOBILE }),

		finishAssessment() {
			const dataParams = { riskId: this.riskResult.riskId, ...this.dataForm }

			const indexOther = dataParams.channel.lastIndexOf(COMMON_CHECK.OTHER)
			if (indexOther > -1) dataParams.channel[indexOther] = dataParams.channelText
			delete dataParams.channelText

			this.isMobile ? this.FINISH_ASSESSMENT_MOBILE(dataParams) : this.FINISH_ASSESSMENT(dataParams)
		},

		isOtherCheck(answer) {
			if (isArray(answer)) return answer.includes(COMMON_CHECK.OTHER)
			return answer === COMMON_CHECK.OTHER
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.eval-result {
	@include form;
	@include font-lato;

	&__head {
		background: $linear-gradient-blue;
		border: 1px solid $color-white;
		border-radius: 12px 12px 0px 0px;
		padding: 44px 40px 24px 40px;
		color: $color-white;
		width: 100%;

		h1 {
			font-size: 24px;
		}
	}

	&__body {
		width: 100%;
		padding: 24px 40px;
		background: $color-white;
		border-radius: 0px 0px 12px 12px;

		.other {
			@include input-other;
		}
	}
}

.form-group {
	margin-bottom: 0;

	&__label {
		margin-bottom: 8px;
		font-weight: bold;
		color: $color-primary2;
		font-size: 16px;
	}

	.grid {
		@media (min-width: $xs) {
			grid-template-columns: 1fr 1fr;
		}
	}
}

.grid {
	@include grid-between;
	grid-template-columns: 1fr;
	gap: 5px;

	.error-mess {
		grid-column: 1 / -1;
	}
}

.btn-finish {
	padding: 14px 32px;
	font-weight: bold;
}

// Checkbox item
.checkbox-item {
	display: inline-block;
	position: relative;
	padding: 0 6px;
	margin: 10px 0 0;
}

.checkbox-item input[type="checkbox"] {
	display: none;
}

.checkbox-item label:hover {
	cursor: pointer;
}

.checkbox-item label:before {
	content: " ";
	display: inline-block;
	position: relative;
	top: 3px;
	margin: 0 5px 0 0;
	width: 18px;
	height: 18px;
	border-radius: 4px;
	border: 1px solid $color-gray80;
	background-color: $color-whisper2;
}

.checkbox-item input[type="checkbox"]:checked + label:before {
	border: 1px solid $color-primary2;
	background-color: $color-white;
}

.checkbox-item input[type="checkbox"]:checked + label:after {
	border-radius: 4px;
	width: 12px;
	height: 12px;
	position: absolute;
	top: 6px;
	left: 9px;
	content: " ";
	display: block;
	background: $color-primary2;
}
</style>
